.shis-select{
  overflow: auto;
    label{
        font-size: 12px;
        color: #333333;
        font-weight: 500;
        letter-spacing: 0.22px;
    }

    .p-dropdown-label{
        font-family: 'Poppins';
        font-size: 12px;

    }

    .p-multiselect-panel .p-multiselect-header {
        // display: none;
        flex-direction: column-reverse;
        padding: 0rem 0rem;
        background: transparent;
        border: 0px;
        .p-multiselect-filter-container{
          width: 100%;
          margin-bottom: 11px;
      
          .p-multiselect-filter{
            border: 0px;
            border-bottom: 1px solid #d6e4f7;
            border-radius: 0;
          }
        }
        .p-multiselect-select-all{
          width: 100%;
          padding-left: 9px;
          margin-bottom: 3px;
      
          .p-checkbox::after{
            content: 'Select All';
          position: absolute;
          left: 29px;
          width: 72px;
          font-size: 12px;
          font-family: 'Poppins';
          color: #000000;
          }
        }
        .p-multiselect-close{
          display: none;
        }
      }
}
.ddFooterBtn{
    border-radius: 4px !important;
    width: 24px !important;
    height: 24px !important;
    border-color: #D6E4F7 !important;
    background-color: #F1F6FC;
}

.DDfooter_container{
    padding-left: 0.7rem;
    span{
        font-weight: 400;
        cursor: pointer;
        color: var(--secondary-text);
    }
}
.drop-sync{
.icon-sync{
    border: none !important;
   .pi{
   color: black;
   }
    
}
.icon-sync:hover{
    border: none !important;
    background-color: transparent !important;
}}





.calender-filter-multiselect{
  max-width: 10dvw;
}


.pinloc-cont{
  
  .p-dropdown {
    border: none;
  }

  svg.p-icon.p-dropdown-trigger-icon.p-clickable{
    color: #A4A4A4;
  }

  .p-dropdown-label {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-weight: 400;
  }
}