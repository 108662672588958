.viv_pageHeader {
  .page_title > H3,i,label {
    font-weight: 500;
    font-size: 13px;
    color: var(--secondary-black);
    line-height: 22px;
  }
  .search > i{
    position: absolute !important;
    color: #A4A4A4 !important;
  }

  .p-dropdown{
    height: 34px;

    .p-inputtext{
      padding: 0.5rem 0.75rem;
    }
  }

  .p-multiselect{
    height: 34px;
    .p-multiselect-label{
      padding: 0.5rem 0.5rem !important;
    }
  }
}

.btn-header{
  justify-content: center;
  width: 100%;
  padding: 6px 10px;
  min-width: 137px;
  font-weight: 600 !important;
  .p-button-label{
    flex:none !important
  }
  .pi{
    font-size: 0.75rem !important;
  }
}

.headerSwitch {
  .react-switch-handle{
    height: 20px !important;
    width: 20px !important;
    top: 5px !important;
    left: -3px !important;
  }
  span{
    font-size: 14px;
    font-weight: 400;
  }
}

.back-icon {
  padding: 5px;
  border: 1px solid #d6e4f7;
  background: #f1f6fc;
  border-radius: 5px;
  cursor: pointer;
}

.edit-icon {
  padding: 5px;
  border: 1px solid #d6e4f7;
  background: #f1f6fc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 26px;
}

.btn_headeredit{
  padding: 5px;
    border: 1px solid #d6e4f7;
    background: #f1f6fc;
    border-radius: 5px;
    font-size: 26px;
    padding: 10px 0px 10px 0px !important;
    width: 2.5rem !important;
    color: var(--secondary-black);
    &:hover{
      border: 1px solid #d6e4f7 !important;
    background: #f1f6fc !important;
    color: var(--secondary-black) !important;
    }

    .i-edit,.i-history,.i-bin{
      font-size: 20px;
    }
}

.backBtn{
  border-radius: 8px !important;
    width: 40px !important;
    height: 40px !important;
    border-color: #D6E4F7 !important;
    background-color: #F1F6FC !important;

    .p-button-icon{
      color: var(--secondary-black);
    }
}


.btn-outline{
  width: 126px;
  color: #FF6900 !important;
  font-weight: 600 !important;
  height: 30px !important;
  border-radius: 4.2px !important;
  font-size: 11.76px !important;
  line-height: 15.96px !important;
  padding: 0;
}

.btn-outline:hover{
  background-color: white !important;
}
.btn-outline:focus{
  background-color: white !important;
}

.btn-purchase{
  background-color: #FF6900 !important;
  width: 126px;
 font-weight: 600 !important;
  height: 30px !important;
  border-radius: 4.2px !important;
  font-size: 11.76px !important;
  line-height: 15.96px !important;
  padding: 0;
}

.p-datepicker table th{
  font-size: 12px;
  font-weight: 400;
}

.p-datepicker table tr{
  height: 5.5dvh;
}

.p-datepicker table td > span{
  color: black;
  font-weight: 500;
}

.p-datepicker table td.p-datepicker-today > span{
  background-color: #FFE1CD !important;
  color: white;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next{
  color: #000000;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus{
  box-shadow: none;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month{
  font-weight: 600;
  font-size: 16px;
  padding: 3px;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year{
  font-weight: 600;
  font-size: 16px;
  padding: 3px;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus{
  box-shadow: none;
}

.p-datepicker table td > span:focus{
  box-shadow: none;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight{
  background-color: #FF6900;
  color: white;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight{
  background-color: #FF6900;
  color: white;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus{
  box-shadow: none;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus{
  box-shadow: none;
}

.p-link:focus{
  box-shadow: none;
}

.p-datepicker table td > span.p-highlight{
  background-color: #FF6900 !important;
  color: white !important;
}

.next-prev-handler-container i{
  border-radius: 100%;
  padding: 5px 10px;
  font-size: 12px;
}

.next-prev-handler-container i:hover{
  background: #ECECEC;
}

.custom-tooltip-header.p-tooltip .p-tooltip-text {
    background: #495057;
    color: #ffffff;
    padding: 0.5rem 0.5rem;
    /* margin-top: 10px; */
    font-size: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.legend-info{
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.private_legend{
  width: 18px;
  height: 11px;
  border-radius: 10px;
  background-color: #ADDCFF9F;
}

.scheduled_legend{
  background-color: #EBA1FF9F;
  width: 18px;
  height: 11px;
  border-radius: 10px;
}

.booked_legend{
background-color: #ffabab;
width: 18px;
height: 11px;
border-radius: 10px;
}

.header-multidd-panel{
  top: 118px !important;
  padding-top: 10px !important;
}


@media (max-width: 768px) {
  .legend-info{
font-size: 9px;
  }


}