.days-add-event {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.day-circle-add-event {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.day-circle-add-event.selected {
    background-color: var(--primary-color);
    color: white;
}

.occurance{
    top: 50%;
    transform: translateY(-50%);
    left: 88px;
}

.disabled-increment-btn{
    color: #A4A4A4;
}

.add-event-calender-label{
    color: #000000 !important;
}

.success_popup.edit_is_all_dialog .p-dialog-content{
    justify-content: flex-start;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    padding: 1rem 2rem !important;
}

.success_popup.edit_is_all_dialog .p-dialog-header .p-dialog-title{
    color: #333333 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.error-message-add-event{
    color: #E80000;
    font-weight: 500;
    font-size: 8.5px;
    line-height: 8px;
    text-align: start;
    position: absolute;
    left: 20px;
    bottom: 30px;
}


.p-dialog-mask.addEventMask{
    z-index: 1101 !important;
}

.c-pd {
    padding-bottom: 0 !important;
    padding-top: 25px !important;
}