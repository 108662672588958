.viv_mainLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background: #f8fafd;

  .viv_sidebar {
    .p-sidebar-content {
      padding: 1rem 0.5rem;
    }

    &.half-bar {
      width: 4vw;
      transition: all 0.4s ease-in;
    }

    &.full-bar {
      width: 16vw;
      transition: all 0.4s ease-in;
    }
  }

  .viv_contentWrapper {

    background: #ffffff;

    //border:1px solid black;
    // padding-left: 20px;
    // padding-right: 20px;
    &.full-bar {
      width: 84vw;
      transition: all 0.4s ease-in;
    }

    &.half-bar {
      width: 98vw;
      transition: all 0.4s ease-in;
    }
  }

  &.full-bar {
    width: 16vw;
  }
}

.viv_contentWrapper {
  display: flex;
  flex-direction: column;

  //border:1px solid black;
  .user_tabs_content {
    flex-grow: 1;
  }

  .page_content {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    flex-grow: 1;
    overflow: hidden;
  }

  &.full-bar {
    width: 84vw;
    transition: all 0.4s ease-in;

    .page_footer::before {
      content: '';
      position: absolute;
      display: inline-block;
      height: 1px;
      background: #D6E4F7;
      width: 100%;
      margin-top: -10px;
      left: 0;
    }
  }

  &.half-bar {
    width: 95vw;
    transition: all 0.4s ease-in;

    .page_footer::before {
      content: '';
      position: absolute;
      height: 1px;
      display: inline-block;
      background: #D6E4F7;
      width: 100%;
      margin-top: -10px;
      left: 0;
    }
  }
}

.viv_header {
  .drop-sync{
    width: 12rem;
  }
  .menu-toggle {
    background-color: #fff;
    border: 0px;

    &:enabled:hover {
      background-color: #fff;
      border: 0px;
    }
  }

  .p-button-icon {
    color: black;
  }
}

.full-bar {
  width: 18vw;
  transition: all 0.4s ease-in;
}

//for red color font
.text-danger {
  color: #ff0000;
}

.text-secondary {
  color: var(--secondary-text);
}

.text-black {
  color: #333333;
}

.text-grey {
  color: #8B8B8B;
}

//for blue color font
.text-primary {
  color: var(--primary-color);
  text-decoration: none;
}

.sidebr_header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 2.5rem;
  margin-top: 0rem;

  p{
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 400;
  }
}

//password feild
.p-inputtext {
  width: 100% !important;
}

.p-input-icon-right>i:last-of-type {
  color: var(--secondary-text);
}

//Text input styling
.p-inputtext:enabled:focus {
  border-color: var(--primary-input);
  box-shadow: none;
  outline-offset: 0;
}

.p-inputtext:enabled:hover {
  border-color: var(--primary-input);
}

.p-inputtext {
  border: 1px solid var(--primary-input);
}

//dropdown styling
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-input);
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: var(--primary-input);
}

.p-dropdown {
  border-color: #e6e6e6;
}

//Main button styling
.p-button {
  background-color: var(--primary-color);
  font-size: 14px !important;
  border: 1px solid var(--primary-color);
  height: 38px;
  letter-spacing: 0.22px;

  &.p-button-primary.p-button-outlined,
  &.p-button-primary.p-button-outlined:hover {
    color: var(--primary-color);
    background-color: #fff;
  }

  &.btn-primary,
  &.btn-primary:hover {
    background-color: #FF6900 !important;
    font-size: 12px !important;
    border: 1px solid #FF6900 !important;
    color: #fff;
    height: 35px;
    max-width: 16rem;
  }
  
  &.btn-tertiary,
  &.btn-tertiary:hover {
    background-color: #FF0000 !important;
    font-size: 12px !important;
    border: 1px solid #FF0000 !important;
    color: #fff;
    height: 35px;
    max-width: 10rem;
  }

  &.btn-secondary,
  &.btn-secondary:hover {
    background-color: #C0C0C0 !important;
    font-size: 12px !important;
    border: 1px solid #C0C0C0 !important;
    color: #fff;
    height: 35px;
    max-width: 10rem;
  }

  &.btn-grey,
  &.btn-grey:hover {
    background-color: #C0C0C0 !important;
    font-size: 14px !important;
    border: 1px solid #C0C0C0 !important;
    color: #fff;
  }
}

.p-button-label {
  font-weight: 400;
}

.p-button:focus {
  box-shadow: none;
}

.p-button:enabled:active,
.p-button:enabled:active,
.p-button:enabled:hover {
  border: 1px solid;
  background: var(--primary-color);
}

.inputCalender {
  &::after {
    content: "\e911";
    font-family: "icomoon";
    position: absolute;
    font-size: 22px;
    right: 11px;
    top: 10px;
    color: var(--secondary-black);
  }
}

.form-feild-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-black);
}

.short_heading {
  font-size: 16px;
  color: var(--secondary-black);
  font-weight: 500;
}


.p-datepicker table td>span {
  width: 2.5rem;
  height: 2.5rem;
}

.p-datepicker table td {
  padding: 0rem;
}

.page_content {
  background: #ffffff !important;
  padding: 1rem;
  border-radius: 10px;
}

.clr-b {
  color: var(--secondary-black) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--primary-color);
}

.p-inputswitch {
  height: 1.3rem !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  width: 1.2rem !important;
  height: 1.1rem !important;
  left: 0rem !important;
  top: 10px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  left: 0.45rem !important;
}

.detail-lable {
  color: var(--secondary-black);
  font-weight: 400;
  font-size: 14px;
  margin-top: 15px;
}

.detail-text {
  color: #293752;
  font-weight: 500;
  font-size: 16px;
}

.p-badge.p-badge-success {
  background-color: #28C76F;
}

.content-section {
  border-radius: 16px;
  border: 1px solid #d6e4f7;
  //height: 70vh;
  overflow: auto;
  overflow-x: hidden;
}

.feild_errormsg {
  font-size: 12px;
  color: red;
}

.p-confirm-dialog {
  border-radius: 24px;

  .p-dialog-header {
    padding: 1.25rem !important;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
  }

  .p-dialog-footer {
    padding: 1.5rem 1.5rem 1rem 1.5rem !important;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  min-height: 100px;
}

.p-radiobutton {
  width: 17px !important;
  height: 17px !important;

  .p-radiobutton-box {
    width: 17px !important;
    height: 17px !important;

    &.p-highlight {
      border-color: var(--primary-color) !important;
      background: var(--primary-color) !important;
    }

    .p-radiobutton-icon {
      width: 7px !important;
      height: 7px !important;
    }
  }
}

.p-inline-message {
  justify-content: left;
  border-radius: 12px;

  &.p-inline-message-info {
    background: #E0EEFB !important;
    color: #2D3748 !important;
    font-weight: 500;
  }

  .p-inline-message-icon {
    color: #62ACED !important;
    font-size: 2rem !important;
  }
}

.border-none {
  border: 0px;
}

.viv-card {
  border-radius: 16px;
  border: 1px solid #d6e4f7;
  height: auto;
  overflow: auto;
  overflow-x: hidden;
}

.gridItemCnfrmDialog {
  .p-confirm-popup-content {
    padding: 1rem;
  }

  .p-confirm-popup-footer {
    padding: 0 1.25rem 0.5rem 1.25rem;
  }
}

.bd-light {
  border-color: #D6E4F7;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: var(--primary-color);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--primary-color);
}

.overlay-panel-container {
  width: 100%;
  height: 70vh;
  overflow: auto;
  margin-top: 12px;
  padding-bottom: 40px;

}

.overlay-panel {

  .p-panel-header {
    background: #fff;
    border: 1px solid #D6E4F7;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;

    .pi {
      color: var(--secondary-black);
    }
  }

  .p-panel-content {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.p-checkbox .p-checkbox-box[data-p-highlight="true"] {
  background: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.simple-calendar{
  top: 17.5dvh !important;
  left: 17.2dvw !important;
  width: 25% !important;
  border-radius: 10px !important;

  .p-datepicker-header{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom: 1px solid #dee1e8 !important;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 600;
    color: #000000;
    padding-left: 3%;
    padding-right: 3%;
  }
}


.p-tooltip .p-tooltip-text{
    padding: 0.5rem 0.5rem;
    font-size: 10px;
}