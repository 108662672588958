.sideMenus_Bar {
    //background: radial-gradient(circle at 49% 55%, rgba(0, 151, 254, 1) 0%, rgba(32, 84, 217, 1) 0%, rgba(32, 84, 217, 1) 25%, rgba(0, 151, 254, 1) 100%, rgba(0, 151, 254, 1) 100%);
    background-color: #ffffff;
    height: 100vh;

    .p-sidebar-header {
        display: none !important;
    }

    &.full-bar {
        width: 16vw;
        transition: all 0.4s ease-in;


        .p-sidebar-content {
            overflow: hidden !important;
        }

        .single-menu {
            .p-toggleable-content{
                display: none !important;
            }
        }

    }

    &.half-bar {
        width: 4vw;
        transition: all 0.4s ease-in;

        .sidebr_header p {
            display: none;
        }

        .viv_sideMenus svg.p-icon.p-submenu-icon {
            display: none !important;
        }

        .viv_sidemenu_profile_tab p.profile-tab-active i {
            padding-left: 0px !important;
        }

        // .viv_sideMenus{
        //     padding: 0 10px;
        // }

        .p-menuitem-text,
        svg.p-panelmenu-icon,
        .p-toggleable-content {
            display: none;
        }

        // .viv_sidemenu_profile_tab {
        //     padding: inherit;
        //   }

        .p-menuitem-icon {
            font-size: 13px !important;
            // padding: 0 10px !important;
        }

        span.p-menuitem-icon {
            position: static !important;
        }

        .p-panelmenu-header-link {
            justify-content: center;
            max-width: min-content;
            width: 34px !important;
        }

        .viv_sidemenu_profile_tab i {
            font-size: 13px !important;
            margin: 0px !important;
        }

        .viv_sidemenu_profile_tab p {
            justify-content: center !important;
            padding: 0.6rem 0;
        }

        .viv_sidemenu_profile_tab p span {
            display: none;
        }

        .p-sidebar-content {
            overflow: hidden !important;
            padding: 1rem 1.1rem !important;
        }

        .sidebr_header {
            margin-bottom: 3.7rem;
        }

        .half-bar-logo-img {
            height: 34px !important;
        }

        .p-panelmenu-panel {
            margin-bottom: 15px;

            &.activepanel::after {
                content: '';
                width: 50px;
                height: 50px;
                background: #ffffff36;
                position: absolute;
                border-radius: 5px;
                margin-top: -36px;
                margin-left: -11px;
            }
        }
        .p-menuitem-count {
            display: none;
        }
    }

    &:hover {
        .btn_sidebrExpand {
            visibility: visible;
        }
    }
}

.sidebar_dialog {
    max-height: 100%;
    margin: 0 !important;

    .p-dialog-header {
        display: none;
    }

    .p-dialog-content {
        padding: 0 1rem 2rem 1rem;
    }

    .mobile_sidebarCloseBtn {
        background: #fff;
        border-radius: 50px;
        color: #374151;
        width: 2rem;
        height: 2rem;
        border: 0px
    }

    .p-panelmenu-content .p-menuitem::after {
        z-index: 1;
    }
}


.btn_sidebrExpand {
    position: absolute !important;
    right: -11px;
    top: 53px;
    width: 22px !important;
    height: 22px !important;
    background-color: #F0F0F0 !important;
    border-color: #F0F0F0 !important;
    visibility: visible;

    .p-button-icon {
        color: #1E232C;
        font-size: 10px;
    }

    &:hover {
        background-color: #fff;
        border-color: #fff;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.viv_sideMenus {

    .p-panelmenu-header {
        position: relative;
        z-index: 2;

        a {
            &:focus {
                box-shadow: none;
            }
        }
    }

    .p-panelmenu-header-link,
    .p-menuitem-link {
        //background: none !important;
        border: none !important;
        //border-radius: unset !important;
        position: relative !important;
        color: #fff !important;
        padding: 0.75rem !important;
        font-size: 12px !important;
        line-height: 22px;
        font-weight: 400 !important;
    }



    // .p-panelmenu-header-link{
    //     flex-direction: row-reverse !important;
    //     justify-content: space-between !important;
    // }

    svg.p-panelmenu-icon {
        position: absolute;
        right: 0;
        border: 2px solid #fff !important;
        border-radius: 4px !important;
        padding: 1px !important;
    }

    .p-menuitem-icon,
    .p-panelmenu-icon {
        font-size: 13px !important;
        color: #000 !important;

    }

    span.p-menuitem-icon {
        position: absolute !important;
        left: 13px !important;
    }

    svg.p-icon.p-submenu-icon {
        color: #FFFFFF;
        position: absolute;
        right: 14px;
    }

    .p-panelmenu-content {
        background: transparent;
        border: none;
        margin-left: 10px;

        .p-panelmenu-root-submenu {

            li.active {
                &::after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50px;
                    background: #fff;
                    position: absolute;
                    margin-top: -23px;
                    margin-left: -3px;
                }

                a {
                    background: #ffffff36 !important;
                    border-radius: 10px !important;
                }
            }

            .p-menuitem-link {

                margin-left: 12px;
                flex-direction: row-reverse;
                justify-content: left;
                gap: 15px;

                &:focus {
                    box-shadow: none
                }

                &.active {
                    background: #ffffff36 !important;
                    border-radius: 10px !important;

                }
            }

            span.p-menuitem-icon {
                position: unset !important;
            }

            .p-menuitem-text {
                color: #fff !important;
                margin-left: 0px !important;
            }

        }
    }

    .p-menuitem-text {
        color: #333333 !important;
        margin-left: 36px !important;
        font-weight: 500;
    }

    .p-menuitem-count {
        position: absolute;
        background: #FF6900;
        color: #fff;
        padding: 0.2rem 0.2rem;
        border-radius: 50px;
        right: 17px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .large-no {
        font-size: 11px;
    }
    .small-no {
        font-size: 9px;
    }





    // .p-submenu-list {
    //     &::before {
    //         content: '';
    //         width: 2px;
    //         height: 25%;
    //         margin-top: 15px;
    //         background-color: #F2F2F280;
    //         ;
    //         position: absolute;
    //     }
    // }
}

.p-menuitem {
    position: relative;
}

// .p-menuitem::before {
//         content: '';
//         width: 2px;
//         height: 38px;
//         margin-top: 18px;
//         background:url(../../assets/images/Long.png);
//         position: absolute;
//         left: -2.5px;
//         top: 8px;
//     }



.p-panelmenu-content .p-menuitem::after {
    content: "";
    width: 12px;
    height: 46px;
    /* margin-top: 18px; */
    background: url(../../assets/images/Long.png);
    position: absolute;
    left: -14.5px;
    top: -27px;
    z-index: -1;
}


// .p-menuitem::after {
//  content: "";
// width: 12px;
// height: 46px;
// /* margin-top: 18px; */
// background: url(../../assets/images/Long.png);
// position: absolute;
// left: -14.5px;
// top: -27px;
// z-index: -1;
// }

.p-menuitem:last-child::before {
    display: none;
}

.p-submenu-list .p-menuitem-text {
    margin-left: 0px !important;
}

.p-submenu-list {
    padding: 0 0 0 2rem !important;
}


/* Styles for the selected menu item */
.selected-menu {
    background-color: black !important;
    color: white !important;
    /* Text color becomes yellow */
    border-radius: 8px;
    /* Rounded corners */
}

/* Assuming the icon class changes when selected */
.i-dashboard-white {
    color: white !important;
    /* Icon color becomes yellow */
}

.viv_sideMenus.p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content,
.viv_sideMenus.p-panelmenu .p-panelmenu-header-link.active {
    background-color: #f0f0f0 !important;
    border: none !important;
    color: #333333 !important;
    border-radius: 5px !important;
}

.viv_sideMenus.p-panelmenu .activepanel .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content,
.viv_sideMenus.p-panelmenu .p-panelmenu-header-link.active {
    background-color: #1E232C !important;
    border: none !important;
    color: #ffffff;
    border-radius: 5px !important;
}

.viv_sideMenus svg.p-icon.p-submenu-icon {
    color: #1E232C;
}

.viv_sideMenus .activepanel .p-highlight .p-menuitem-text,
.viv_sideMenus .activepanel .p-highlight .p-menuitem-icon,
.viv_sideMenus .activepanel .p-highlight svg.p-icon.p-submenu-icon,
.viv_sideMenus .p-panelmenu-header-link.active .p-menuitem-icon,
.viv_sideMenus .p-panelmenu-header-link.active .p-menuitem-text {
    color: #ffffff !important;
}

.viv_sideMenus.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    background-color: #FFFFFF !important;
    border: none !important;
}

.viv_sideMenus.p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content {
    color: #4b5563;
    transition: box-shadow all 0.4s ease;
    border-radius: 5px;
}

.viv_sideMenus.p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
    color: #4b5563;
    background: #E8E8E8;
}

.viv_sidemenu_profile_tab {
    position: absolute;
    bottom: 0;
    min-width: -webkit-fill-available;
    left: 0;
    padding: 10px;
    border-top: 1px solid #DEE1E8;
}

.viv_sidemenu_profile_tab p {
    color: #333333 !important;
    font-size: 15px;
    font-weight: 500;
    padding: 0.5rem 0.6rem;
}

// .viv_sidemenu_profile_tab p{
//     font-size: 13px;
//     font-weight: "bold  ";
//     color: #333333;
// }

.viv_sidemenu_profile_tab p.profile-tab-active {
    background-color: #1E232C !important;
    border: none !important;
    color: #ffffff;
    border-radius: 5px !important;
    // margin:-6px;
}

.viv_sidemenu_profile_tab p.profile-tab-active span {
    color: #ffffff !important;
    font-size: 13px;
    font-weight: "bold  ";
}

.viv_sidemenu_profile_tab p.profile-tab-active i {
    color: #ffffff !important;
    font-size: 15px;
    padding-bottom: 3px;
    padding-left: 8px;
}

.viv_sidemenu_profile_tab p.profile-tab-active span {
    color: rgb(207, 9, 42);
}

.viv_sidemenu_profile_tab p span {
    font-size: 12px;
}

.viv_sidemenu_profile_tab a {
    color: #333333 !important;
}

.viv_sidemenu_profile_tab i {
    font-size: 13px !important;
    color: #000 !important;
}

.active {
    background-color: #f0f0f0 !important;
    border-radius: 5px !important;
}

.notactive:hover {
    border-radius: 5px !important;
}

.custom-tooltip-menu.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: transparent;
}

.custom-tooltip-menu.p-tooltip .p-tooltip-text {
    font-size: 10px !important;
    padding: 5px !important;
}


.logout-span {
    font-size: 12px;
    color: #333333;
    font-weight: 500;

    &:hover {
        color: #FF6900;
    }
}

/* Container styling */
.apptoggle-switch {
    position: relative;
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    overflow: hidden;
    width: 100%; /* Adjust width */
    height: 40px; /* Adjust height */
    background-color: #fff;
    cursor: pointer;
    font-family: Arial, sans-serif;
  }
  
  /* Option styling */
  .toggle-option {
    flex: 1;
    text-align: center;
    line-height: 40px;
    color: orange; /* Default color for inactive options */
    font-size: 14px;
    position: relative;
    z-index: 2; /* Place above sliding background */
    transition: color 0.3s ease-in-out;
  }
  
  .toggle-option.active-opt {
    color: #fff; /* Text color for active option */
    font-weight: bold;
  }
  
  /* Sliding background for the active option */
  .toggle-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: orange;
    border-radius: 25px;
    z-index: 1; /* Place below options */
    transition: transform 0.3s ease-in-out;
  }
  