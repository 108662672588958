/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .login_form {
    height: 100dvh;

    form {
      width: 100dvw !important;
    }

    h1 {
      font-size: 32px !important;
    }

    p {
      font-size: 14px !important;
    }
  }
  .resp_footer {
    position: absolute !important;
    bottom: 20px !important;
  }

  .viv_tabs {
    .p-tabview-nav-next {
      display: block;
    }
  }

  .viv_grid {
    display: flex;
    flex: 1;
    .p-datatable-wrapper {
      height: 63dvh !important;
    }

    .p-paginator {
      right: -17px !important;
    }
  }

  .viv_header {
    padding: 10px 20px 10px 20px !important;
    height: 50px !important;

    justify-content: space-between;

    .drop-sync {
      width: 5rem !important;
    }

    img {
      display: none;
    }

    .p-input-icon-right {
      width: 79% !important;
    }
  }

  .viv_webheader{
    display: none !important;
  }

  .page_title{
    margin-left: 0.5rem !important;
  }

  .md-text {
    font-size: 0.9rem !important;
  }

  .multiselect-role-custom .p-checkbox .p-checkbox-box{
    width: 16px !important;
    height: 16px !important;
  }

  .VIV-tree-dropdown.p-tree .p-treenode-label{
    font-size: 12px !important;
  }

  .viv_pageHeader {
    // align-items: flex-start !important;
    
    .page_title{
      // align-items: flex-start !important;
      width: 100% !important;
    }

    .headerSwitch {
      align-items: center !important;
    }

    .page_items {
      gap: 1rem !important;
    }

    .actions-container {
      // display: none !important;

      .p-dropdown{
        width: 70px !important;
      }

      .btn-add {
        position: absolute;
        bottom: 2.6dvh;
        right: 20px;
        border-radius: 50px;
        min-width: 50px !important;
        width: 50px !important;
        height: 50px;
        z-index: 1;

        .p-button-label {
          display: none;
        }

        .p-button-icon {
          margin: 0;
          font-size: 1.2rem !important;
        }
      }

      .ic-filter{
        margin-right: 0px !important;
        font-size: 11px;
      }
    }

    .p-calendar {
      display: none;
    }

    .page-title {
      display: none;
    }
  }

  .p-paginator-current{
    margin: 0 !important;
  }

  .viv_paginator {
    flex-direction: column !important;
    margin-top: 5px !important;
    height: 20% !important;
    padding-bottom: 3rem !important;
    justify-content: space-around !important;
  }

  .viv_sidebar {
    display: none;
  }

  .viv_contentWrapper {
    width: 100vw !important;

    .page_content {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  .hidden-xs {
    display: none !important;
  }

  .clr-b {
    padding: 20px;
  }

  .sign_up_dialog {
    max-height: 100dvh;

    .p-dialog-header,
    .p-dialog-footer {
      border-radius: 0px;
    }

    .p-dialog-footer {
      padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
  }

  .profile-img {
    width: 70px !important;
    height: 70px !important;
  }

  .sidebar_dialog {
    max-height: 100% !important;
  }

  .simple-calendar {
    width: 85% !important;
    top: 14.5dvh !important;
    left: 8.2dvw !important;
    border-radius: 10px !important;
  }

  .p-paginator span:nth-of-type(1){
    font-size: 12px;
    text-align: center;
    width: 32% !important;
  }

  .p-paginator span:nth-of-type(2){
    width: 25% !important;
    font-size: 12px !important;
  }

  .p-paginator .p-dropdown{
    margin-left: 0px !important;
    width: 16% !important;
  }

  .p-dropdown .p-dropdown-trigger{
    width: 7dvw !important;
  }

  .p-dropdown .p-dropdown-trigger svg{
    width: 35.5% !important;
    height: 35.5% !important;
  }

  .p-paginator-current{
    font-size: 12px !important;
    width: 100dvw !important;
  }

  .p-paginator .p-icon{
    width: 100% !important;
    height: 100% !important;
  }

  .p-paginator .p-paginator-element{
    min-width: fit-content !important;
    height: fit-content !important;
  }

  .viv_grid .activeStatus{
    font-size: 11px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .viv_grid .InactiveStatus{
    font-size: 11px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .grid_actionsList {
    left: calc(100dvw - 35dvw) !important;
  }

  .pagginator-width{
    width: 100% !important;
  }
  .p-paginator .p-paginator-first{
    margin: 10 !important;
  }
  .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last{
    margin: 0 !important;
  }

  .viv_grid .p-column-header-content{
    min-width: 124px;
  }
  
  .form-buttons{
    align-items: center;

    div{
      justify-content: center !important;
    }
  }

  .rtcontainer-active{
    width: 90dvw !important;
  }

  .ttcontainer {
    width: 90dvw !important;
    height: 280px !important;
    flex-direction: column !important;
    padding-right: 1.25rem !important;
    margin-left: 0rem !important;
    .pbcont {
      width: 100% !important;
    }
    .pbcont-child{
      margin-top: 0 !important;
      width: 70dvw !important;
    }
  }

  // .viv_pageHeader {
  //   padding-left: 0 !important;
  // }
  
  .page_items {
    .search {
      width: 90dvw !important;
    }
    button {
      width: 87dvw !important;
    }
  }

  .p-dialog{
    width: 95% !important;
  }

  .pay-button{
    width: 40dvw !important;
  }

  .p-dialog-mask{
    z-index: 6000 !important;
  }

  .p-multiselect-panel,.p-dropdown-panel{
    z-index: 9132 !important;
  }

  .p-menuitem-count{
    margin-left: 48dvw !important;
  }

  .btn-header{
    max-width: 100% !important;
  }

  .infor-container{
    flex-direction: column;
    gap: 1rem;
  }

}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}

/* Samsung Galaxy Fold ----------- */
@media (min-width: 280px) and (max-width: 320px) {
  .viv_sidebar {
    display: none;
  }

  .viv_contentWrapper {
    width: 100vw !important;
  }

  .viv_header {
    //flex-direction: column;
    //gap: 1rem;
    padding-left: 5px !important;
    padding-right: 5px !important;

    .search {
      display: none;
    }
  }

  .sidebar_dialog {
    width: 90vw;

    .p-dialog-content {
      overflow: hidden;
    }

    .sidebr_header {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }

  .clr-b {
    padding: 20px;
  }

  // .p-dialog-mask{
  //   position: absolute;
  //   top: 127px;
  // }

  // .hidden-xs{
  //   display: none;
  // }
}

/* Surface Duo - Single Screen Mode */
@media only screen and (max-width: 540px) and (min-width: 360px) and (orientation: portrait),
       only screen and (max-width: 720px) and (orientation: landscape) {
  /* Your styles for Surface Duo in single-screen mode */
  .viv_sidebar {
    display: none;
  }

  .viv_webheader{
    display: none !important;
  }

  .viv_contentWrapper {
    width: 100vw !important;
  }

  .sidebar_dialog {
    width: 55dvw !important;
    min-height: 100dvh !important;
  }

  .p-menuitem-count{
    margin-left: 0 !important;
    right: 0% !important;
  }

  .top-bar-img{
    width: 40px;
    height: 40px;
  }
}

/* Samsung Galaxy A7 Lite Tab ----------- */
@media only screen and (min-device-width : 600px) and (max-device-width : 800px) {
  .viv_contentWrapper {
    width: 100vw !important;
  }

  .viv_mainLayout .viv_sidebar.full-bar{
    width: 22vw !important;
    display: none;
  }

  .viv_webheader{
    display: none !important;
  }

  .sideMenus_Bar {
    display: none;
    transition: 0.2s;
  }

  .sideMenus_Bar.full-bar{
    width: 22vw;
  }

  .viv_tabs {
    .p-tabview-nav-next {
      display: block;
    }
  }

  .viv_grid {

    .p-datatable-wrapper {
      height: 71vh;
    }

    .p-paginator {
      right: -17px !important;
    }
  }

  .hidden-sm {
    display: none !important;
  }

  .sidebar_dialog {
    width: 40vw !important;
    min-height: 100dvh !important;
  }

  .p-menuitem-count{
    margin-left: 0px !important;
    right: 0 !important;
  }

  .clr-b {
    padding: 20px;
  }

  .viv_paginator {
    flex-direction: column !important;
  }

  .pagginator-width {
    width: 100% !important;
  }

  .viv_grid .p-datatable-wrapper {
    height: calc(100vh - 222px) !important;
  }

  .pagginator-width{
    width: 100% !important;
  }

  .btn-add {
    max-width: 100% !important;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 712px) and (max-device-width : 1023px) {
 

  .viv_contentWrapper {
    width: 100vw !important;
  }

  .viv_mainLayout .viv_sidebar.full-bar{
    width: 22vw !important;
    display: none;
  }

  .viv_webheader{
    display: none !important;
  }

  .viv_mobileheader{
    display: flex !important;
  }

  .sideMenus_Bar {
    display: none;
    transition: 0.2s;
  }

  .sideMenus_Bar.full-bar{
    width: 22vw !important;
  }

  .viv_tabs {
    .p-tabview-nav-next {
      display: block;
    }
  }

  .viv_grid {

    .p-datatable-wrapper {
      height: 71vh;
    }

    .p-paginator {
      right: -17px !important;
    }
  }

  .hidden-sm {
    display: none !important;
  }

  .sidebar_dialog {
    width: 40vw !important;
    min-height: 100dvh !important;
  }

  .clr-b {
    padding: 20px;
  }

  .viv_paginator {
    flex-direction: column !important;
  }

  .pagginator-width {
    width: 100% !important;
  }

  .viv_grid .p-datatable-wrapper {
    height: calc(100vh - 222px) !important;
  }

  .pagginator-width{
    width: 100% !important;
  }

  .p-menuitem-count{
    margin-left: 0 !important;
    right: 0% !important;
  }
}

/* iPad Pro */
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (-webkit-min-device-pixel-ratio: 2){
  .viv_mainLayout .viv_sidebar.full-bar{
    width: 23vw !important;
  }

  .viv_mainLayout .viv_sidebar.half-bar{
    width: 6vw !important;
  }

  .sideMenus_Bar.full-bar{
    width: 23vw !important;
  }

  .sideMenus_Bar.half-bar{
    width: 6vw !important;
  }

  .p-menuitem-count{
    margin-left: 0 !important;
    right: 0% !important;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-device-width: 1367px) and (-webkit-max-device-pixel-ratio: 1.5) {

  .content-section {
    height: 70vh;
  }

  .viv_sidebar {
    width: 20vw;
  }

  .page_footer {
    position: absolute;
    bottom: 10px;
  }

  .viv_contentWrapper {
    width: 80vw;

    &.full-bar {
      .page_footer {
        width: 79%;
      }
    }

    &.half-bar {
      .page_footer {
        width: 92%;
      }
    }
  }

  .hidden-md {
    display: none !important;
  }

  .default-content {
    height: calc(100vh - 30vh);
  }

  .viv_grid {
    &.summary_grid {
      .p-datatable-wrapper {
        max-height: 65px;
        overflow: hidden !important;
      }

      td {
        font-weight: 600 !important;
      }
    }

    &.showSummary {
      .p-datatable-wrapper {
        height: 55vh;
      }
    }

    &.SO-LineItems {
      .p-datatable-wrapper {
        height: 25vh;
      }
    }

    &.NO-LineItems {
      .p-datatable-wrapper {
        height: auto;
      }
    }

    .p-datatable-wrapper {
      height: 59vh;
    }

    &.pick-LineItems {
      .p-datatable-wrapper {
        height: auto;
      }
    }

    .p-paginator {
      right: -3px !important;
    }
  }

  .viv_editablegrid {
    .p-datatable-wrapper {
      height: 37vh !important;
      max-height: none;
    }
  }

  .lineItem_dialog {
    .p-datatable-wrapper {
      height: 40vh;
    }
  }

  .VIVGRidContainer {
    height: 50vh;
    overflow: hidden;
  }

  .pagginator-width {
    width: 58.3333%;
  }

  .grid_actionsList {
    left: calc(100dvw - 10dvw) !important;
  }
}


/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  
  .viv_sidebar {
    &.full-bar{
      width: 13dvw !important;
    }

    .sideMenus_Bar.full-bar{
      width: 13dvw;
    }
  }

  .viv_mainLayout .viv_contentWrapper.full-bar{
    width: 87dvw !important;
  }
  
  .p-datatable-wrapper {
    height: 70vh;
  }

  .VIVGRidContainer {
    height: 67vh !important;
    overflow: hidden;

    .summary_grid .p-datatable-wrapper {
      max-height: 63px !important;
    }
  }

  .viv_grid {
    &.summary_grid {
      .p-datatable-wrapper {
        height: 63px;
        overflow: hidden !important;
      }

      td {
        font-weight: 600 !important;
      }
    }

    .p-datatable-wrapper {
      height: 72vh;
    }
  }

  .viv_editablegrid {
    .p-datatable-wrapper {
      height: 50vh !important;
      max-height: none;
    }
  }

  .viv_grid .p-paginator {
    bottom: -86px !important;
    right: -15px !important;
  }

  .grid_actionsList {
    left: 1609.34px !important;
  }

  .tabs_content,
  .upload-rma-content {
    height: 73vh !important;
  }

  .grid_actionsList {
    left: calc(100vw - 12vw) !important;
  }

  .roles_tabs_content,
  .scanRMA_content,
  .content-section,
  .default-content {
    height: 79vh;
  }

  .scant {
    height: 77vh !important;
  }

  .hidden-md {
    display: none !important;
  }
  
  .hidden-lg {
    display: none !important;
  }

  .viv_grid.SO-LineItems .p-datatable-wrapper {
    height: 35vh;
  }

  .so-from {
    .content-section {
      height: auto;
    }

    .form_wrapper {
      height: 35vh !important;
      overflow: auto;
    }

    .grid_wrapper {
      height: 45vh;
      overflow: auto;
    }
  }

  .lineItem_dialog {
    width: 60vw !important;

    .p-datatable-wrapper {
      height: 30vh;
    }
  }

  .pagginator-width {
    width: 58.3333% !important
  }
  
}