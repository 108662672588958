.day_view_dialog{
        max-height: 100% !important;
        height: 100% !important;
    
    .p-dialog-content{
        overflow: hidden;
    }
    
    .day-calendar{
        overflow-x: hidden !important;
    }
    
     .day_view_close_icon{
        font-size: 11px !important;
        color: #6c757d;
    }
}