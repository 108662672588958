@font-face {
  font-family: 'icomoon';
  /* src:  url('fonts/icomoon.eot?u8qfjo'); */
  src:  url('../fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-iron:before {
  content: "\e92b";
}
.i-up-chev:before {
  content: "\e92a";
}
.i-box:before {
  content: "\e929";
  color: #0097fe;
}
.i-boxgroup:before {
  content: "\e928";
  color: #0097fe;
}
.i-printer:before {
  content: "\e927";
  color: #0097fe;
}
.i-submit:before {
  content: "\e926";
}
.i-history:before {
  content: "\e925";
}
.i-comment:before {
  content: "\e924";
}
.i-marker:before {
  content: "\e923";
  color: #0097fe;
}
.i-menu:before {
  content: "\e922";
}
.i-info:before {
  content: "\e921";
  color: #62aced;
}
.i-location:before {
  content: "\e920";
  color: #0097fe;
}
.i-swap:before {
  content: "\e91f";
  color: #58bafe;
}
.i-arrow-right:before {
  content: "\e91e";
}
.i-bin:before {
  content: "\e91d";
}
.i-eye-hide:before {
  content: "\e91b";
}
.i-eye-show:before {
  content: "\e91c";
}
.i-notify:before {
  content: "\e91a";
  color: #ff8964;
}
.i-chk-circle:before {
  content: "\e919";
  color: #82c785;
}
.i-warning:before {
  content: "\e918";
  color: #0097fe;
}
.i-arrow-icon:before {
  content: "\e917";
}
.i-plus:before {
  content: "\e916";
}
.i-edit:before {
  content: "\e915";
}
.i-double-chk:before {
  content: "\e914";
}
.i-ellipse:before {
  content: "\e913";
}
.i-filter:before {
  content: "\e912";
}
.i-calender:before {
  content: "\e911";
}
.i-import:before {
  content: "\e90f";
}
.i-export:before {
  content: "\e910";
}
.i-down-chev:before {
  content: "\e90e";
}
.i-bell:before {
  content: "\e90d";
}
.i-signout:before {
  content: "\e90b";
}
.i-user:before {
  content: "\e90c";
}
.i-search:before {
  content: "\e90a";
}
.i-left-chev:before {
  content: "\e909";
}
.i-right-chev:before {
  content: "\e908";
}
.i-scanner:before {
  content: "\e907";
}
.i-outbound:before {
  content: "\e901";
}
.i-barchart:before {
  content: "\e902";
}
.i-setup:before {
  content: "\e903";
}
.i-basket:before {
  content: "\e904";
}
.i-inventory:before {
  content: "\e905";
}
.i-dashboard:before {
  content: "\e906";
}
.i-inbound:before {
  content: "\e900";
}

