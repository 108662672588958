
.reservation-cancel-dialog{
    min-width: 438px;
    max-width: 438px;
    background-color: white;
    overflow-x: hidden !important;
}

.reservation-cancel-dialog-header{

border-bottom: 0.89px solid #0000001A;

h1{
    font-weight: 500;
    color: #333333;
    font-size: 15px;
    line-height: 17.58px;
    letter-spacing: 0.23px;
    font-family: 'Roboto';
    }

i{
    color: #A4A4A4;
    font-size: 11px;
    margin-bottom: 13px;
    cursor: pointer;
}

}



.reservation-cancel-schedule{
    display: flex;
    flex-direction: row;
    
}

.reservation-cancel-schedule>i{
font-size: 15.05px;
align-self:flex-start;
}


.reservation-cancel-schedule>div>p{
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 21.29px;
    margin-top: -3px;
    color: #000000;
}

.reservation-cancel-schedule>div>span{
    font-size: 11px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 21.29px;
    color: #333333;
}



.court-info>div>span{
    font-size: 11px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 21.29px;
    color: #333333;
}

.error-message-view-event{
    color: #E80000;
    font-weight: 500;
    font-size: 8.5px;
    line-height: 8px;
    text-align: start;
}

.reservation-cancel-list-footer{
    border-top: 1px solid #0000001A;
}


.reservation-cancel-footer-btn{
    .p-button, .p-button:hover {
        min-width: 12rem;
        max-width: 20rem;
}

}



/* Mobile screen adjustments */
@media (max-width: 768px) {

    .reservation-cancel-dialog{
        min-width: 300px;
    }

}