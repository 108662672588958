

.advanced-filter-sidebar{
min-width: 328px;
  top: 75px !important;
  border-top-left-radius: 24px;

  .p-sidebar-close{
    display: none;
  }

  
 
}

.advanced-filter-header{
    border-bottom: 1px solid black;


   h1{
    font-size: 18px !important ;
    font-family: 'Poppins' !important;
    line-height: 27px !important;
    color: #000000 !important;
   } 
}


.advanced-filter-main{
    min-height: 80%;

   label{
    color: #333333;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.26px;
   }


}


