@import url("https://fonts.googleapis.com/css2?family=Public%20Sans:wght@300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
}

body,
body .p-component {
  font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-weight: 500;
  line-height: 1.2;
  color: var(--surface-900);

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

a{
  color: var(--primary-color);
  text-decoration: none;
}

.href-underline{
  text-decoration: underline;
}

.fs{
  &.f-16{
    font-size: 16px;
  }
  &.f-14{
    font-size: 14px;
  }
  &.f-12{
    font-size: 12px;
  }
  &.f-30{
    font-size: 30px;
  }
  &.f-40{
    font-size: 40px;
  }
}

.fw-4{
  font-weight: 400;
}
.fw-5{
  font-weight: 500;
}
.fw-6{
  font-weight: 600;
}
.fw-7{
  font-weight: 700;
}

@media only screen and (min-width: 1024px) and (max-width: 1279px){
  .fs{
    &.f-16{
      font-size: 14px;
    }
    &.f-14{
      font-size: 12px;
    }
    &.f-12{
      font-size: 11px;
    }
    &.f-30{
      font-size: 25px;
    }
    &.f-40{
      font-size: 30px;
    }
  }
}

@media only screen and (min-width : 1824px){}