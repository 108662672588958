@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.event-panel{
    
    .court-count-wraper {
        background: #C5E6FF;
        border: 1px solid #ADDCFF;
        min-width: 28.7px;
        min-height: 27.8px;
       border-radius: 100%;
       display: flex;
       flex-direction: row;
       justify-items: center;
       justify-content: center;
    
        p {
            font-size: 14.41px;
            font-weight: 500;
            color: #333333;
            line-height: 21.62px;
            font-family: 'Poppins';
            align-self: center;
        }
    }

    .p-panel-header{
        background: #fff;
    }

    .p-panel-content{
        background-color: #FCFCFC;
    }

    .p-panel-header-icon{
        background: #D8D6D5 !important;
        &:focus{
            box-shadow: none !important;
        }
        span{
            font-size: 7px;
            color: #fff;
        }
    }
}




.event-list{
    max-height: 459px;
    overflow-y: auto;
}

.event-title{
    font-size: 14.93px;
    color: #000000;
    font-family: 'Poppins';
    line-height: 35.84px;
}


.event-dialog-header{
    
 border-bottom: 1.58px solid #0000001A;
 
}

.event-dialog-header-h1{
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 19.76px;
    line-height: 23.15px;
    letter-spacing: 0.41px;
}







.event-detail-col{
    display: flex;
    flex-direction: row;
    
}

.event-detail-col>i{
    font-size: 14px;
    margin-top: 1px;

}


.event-detail-col>p{
    font-size: 14.63px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 29.26px;
    margin-top: -6px;
    color: #000000;
}

.event-detail-col>p>span{
    font-size: 13.41px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 29.26px;
    text-align: center;
    color: #333333;
}

.event-detail-edit>img{
width: 9px;
height: 10px;
cursor: pointer;
}



/* Mobile screen adjustments */
@media (max-width: 768px) {
    .event-detail-edit {
        order: -1; 
        width: 100%; 
        justify-content: space-between; 
    }

    .event-title{
        font-size: 12px;
        line-height: 12px;
    }

    .court-count-wraper {
        
        
    
        p {
            font-size: 10px !important ;
            line-height: 10px !important;
        }
    }



.viv_dialog>.p-dialog-content{
    padding-inline: 10px !important;
    overflow: hidden;
}

    .token_cost{
        font-size: 10px !important ;
            line-height: 10px !important;
    }


    

}

