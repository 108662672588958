.export_popup {
    border-radius: 10px;
    
    .popup-icon{
        font-size: 50px;
        color: rgba(0, 0, 0, 0.5098039216);
    }

    .p-dialog-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom: 1px solid #0000001A;

        .p-dialog-header-icon{
            color: var(--secondary-black) !important;

            &:hover{
                background: #F1F6FC !important;
            }

            &:focus{
                box-shadow: none;
            }
        }

        .p-dialog-title{
           font-size: 20px;
           font-weight: 600;
           line-height: 16px;
           color: #7C7C7C;
        }
    }
    
    

    .p-dialog-footer {
        border-top: 1px solid #0000001A;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: #ffffff;
    }

    .p-dialog-content{
        padding: 2.5rem 3.5rem 2.5rem 3.5rem !important;
        font-size: 16px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        font-weight: 500;
    }

    .p-button-text{
        background-color: #F1F6FC;
        border: 1px solid #D6E4F7;
        color: var(--secondary-black) !important;

        &:hover{
            background-color: #F1F6FC !important;
        border: 1px solid #D6E4F7 !important;
        }
    }
}


@media (max-width: 767px) {
  .export_popup, .success_popup{
    width: 96vw !important;
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  .export_popup, .success_popup{
    width: 45vw !important;
  }
}
