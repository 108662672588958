.viv-slot-reserve-dialog{
    
    padding-top: 5px;
    
    .cs-border-btm{
        border-bottom: 0.89px solid rgba(0, 0, 0, 0.1019607843);
        padding-bottom: 20px;
    }
    
    p{
        font-size: 12px;
    }
    
    i{
        font-size: 15px;
    }
    .reserve-headr{
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
    }
    span{
        font-size: 11px;
        color: rgba(51, 51, 51, 1);
    }
    
    .reserve-available-court{
        
        
        .outer-circle {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 2px solid rgba(185, 185, 185, 1); /* Outer border color (orange) */
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s, background-color 0.3s;
}

.inner-circle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #fff; /* Inner circle color (orange) */
  transition: background-color 0.3s;
}

.outer-circle.selected{
    border-color: rgba(255, 105, 0, 1);
}

.outer-circle.selected .inner-circle {
  background-color: rgba(255, 105, 0, 1); /* Inner circle when selected */
}

.outer-circle.disabled {
  border-color: rgba(164, 164, 164, 1); /* Outer border color when disabled */
  cursor: not-allowed;
}

.outer-circle.disabled .inner-circle {
  background-color: rgba(164, 164, 164, 1); /* Inner circle when disabled */
}

        i{
            
            font-size: 24px;
            padding: 10px 20px; 
            border-radius: 5px;
            background-color: rgba(245, 245, 245, 0.71);
            border: 1px solid rgba(245, 245, 245, 0.71);
        }
        .court-select {
            i{
            background-color: rgba(255, 105, 0, 0.1);
            color: rgba(255, 105, 0, 1);
            border: 1px solid rgba(255, 105, 0, 0.5);
        }
        
        i:before{
            color: rgba(255, 105, 0, 1);
        }
    }
    }
}




