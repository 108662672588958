.month-event-view-dialog{
    min-width: 296px;
    background-color: white;
    border-radius: 10px;
    
    main{
        max-height: 170px !important;
        overflow-y: auto;
    }
   
}

.month-event-view-dialog-header{
    border-bottom: 1px solid #0000001A;
}



.month-event-view-dialog-header>h1{
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 15px;
    line-height: 17.58px;
    letter-spacing: 0.26px;
    color: #333333;
    

}

.month-event-view-dialog-header>i{
    font-size: 13px;
    cursor: pointer;
    color: #A4A4A4;
}

.month-event-description>p{
    font-size: 11px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 20px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 85%;
}

.month-event-description>span{
    font-size: 9px;
    line-height: 13.5px;
    font-weight: 600;
    font-family: 'Poppins';
    align-self: center;
}