  .slot-reserve-tooltip.p-tooltip .p-tooltip-text{
    font-size: 10px !important;
    padding: 5px !important;
  }
  
  .inivte-they-pay-location-schedule{
    i{
        font-size: 12px !important;
    }
    
    .ic-close{
        font-size: 9px !important;
    }
    
    p{
        font-size: 8px !important;
    }
    
    .icon-color-differ:before{
        color: #CECECE;
    }
    

    
    .custom-avatar-pay .p-avatar-text {
    font-weight: 500;
    color: white;
    font-size: 12px;
}


.custom-avatar-pay.p-avatar {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
}

.p-chip{
  padding: 0 0.6rem;
}

.p-chip.rejected{
  background-color: #FF0000;
}

.p-chip.paid{
  background-color: #0AA758;
}

.p-chip.pending{
  background-color: #FFC107;
}

.p-chip.accepted{
  background-color: #0AA758;
}

.p-chip-text{
    color: #FFFFFF;
    font-size: 7px;
    font-weight: 500;
    margin-block: 1px !important;
}
  }

  .w-30 {
    width: 30%;
  }

  .w-70 {
    width: 70%;
  }
  
  .private-reserve-dialog-footer{
    padding: 2rem 0.5rem 0rem 0.5rem !important;
    
    
    .p-button{
      max-width: 12rem !important;
    }
  }
  
  .private-reserve-dialog-footer .p-button-label{
    color: white !important;
    font-size: 12px !important;
  }
  
  
      .panel-disabled .panel-header-i{
      color: #979797 !important;
    }
    
    .panel-disabled .icon-color-differ:before{
        color: #CECECE;
    }

.send-icon {
  transform: rotate(45deg);
  color:#979797; 
  position: absolute;
  right: 11%;
  top: 60%;
  font-size: 16px !important;
  cursor: pointer;

  :active {
    color: #FF6900 !important;
  }
}