@font-face {
  font-family: 'trespalapasIcon';
  src:  url('../fonts/trespalapasIcon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic-"], [class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'trespalapasIcon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-bell:before {
  content: "\e926";
  color: #8d8d8d;
}
.ic-pin-location .path1:before {
  content: "\e91e";
  color: rgb(141, 141, 141);
}
.ic-pin-location .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(141, 141, 141);
}
.ic-pin-location .path3:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 105, 0);
}
.ic-mark:before {
  content: "\e91c";
}
.ic-circle-plus:before {
  content: "\e91d";
}
.ic-error-notify:before {
  content: "\e91b";
  color: #ed4e2a;
}
.ic-square-check:before {
  content: "\e91a";
  color: #3ebf62;
}
.ic-reservation:before {
  content: "\e903";
  color: #ff6900;
}
.ic-reserve-court:before {
  content: "\e905";
  color: #b4b4b4;
}
.ic-filter:before {
  content: "\e902";
  color: #8d8d8d;
}
.ic-greenwallet:before {
  content: "\e900";
  color: #03c64a;
}
.ic-orangecoins:before {
  content: "\e901";
  color: #ff6900;
}
.ic-clock:before {
  content: "\e916";
  color: #ff6900;
}
.ic-location:before {
  content: "\e917";
  color: #ff6900;
}
.ic-court:before {
  content: "\e918";
  color: #ff6900;
}
.ic-check:before {
  content: "\e919";
  color: #0aa758;
}
.ic-edit:before {
  content: "\e915";
  color: #b3b3b3;
}
.ic-chev-right:before {
  content: "\e913";
  color: #606368;
}
.ic-chev-left:before {
  content: "\e914";
  color: #606368;
}
.ic-chev-up:before {
  content: "\e911";
}
.ic-chev-down:before {
  content: "\e912";
}
.ic-close:before {
  content: "\e910";
}
.ic-star:before {
  content: "\e927";
}
.ic-real-star:before {
  content: "\e90f";
}
.ic-setting:before {
  content: "\e907";
}
.ic-franchise:before {
  content: "\e908";
}
.ic-calender:before {
  content: "\e909";
}
.ic-wallet:before {
  content: "\e90a";
}
.ic-gift-token:before {
  content: "\e90b";
}
.ic-member:before {
  content: "\e90c";
}
.ic-event-type:before {
  content: "\e90d";
}
.ic-token-statistics:before {
  content: "\e90e";
}
.ic-exclamation-circle:before {
  content: "\e921";
}
.ic-check-circle:before {
  content: "\e906";
  color: #ff6900;
}
.ic-delete:before {
  content: "\e904";
}
.ic-coin .path1:before {
  content: "\e920";
  color: rgb(252, 203, 33);
}
.ic-coin .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(252, 159, 33);
}
.ic-coin .path3:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(165, 104, 30);
  opacity: 0.5;
}
.ic-coin .path4:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(252, 203, 33);
}