.shis-input{
    position: relative;
    label{
        font-size: 12px;
        color: #000000;
        font-weight: 500;
        letter-spacing: 0.22px;
    }

    .p-inputtext{
        height: 37px;
        font-size: 12px;  
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 10px 10px;
    }
    
    .p-inputtextarea{
        height: auto;
    }

    .p-password{
        
        .p-icon-field{
            width: 100%;
        }
        .p-input-icon{
            top: 30%;
        }
    }

    .pwd-meter-container{
        position: absolute;
        min-width: 100%;
        top: 109%;
        .text-right{
            text-align: right !important;
        }
    }

    .pwd_strength_box{
        background-color: #E4E7EC;
        &.bg-tooweak{
            background-color: #333333;
        }
        &.bg-weak{
            background-color: #EA0C0D;
        }
        &.bg-fair{
            background-color: #FFAD00;
        }
        &.bg-good{
            background-color: #9CC25A;
        }
        &.bg-strong{
            background-color: #02B502;
        }
        height: 4px;
        width: 100%;
        border-radius: 3px;
    }

    .pwd_strength_label{
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        &.bg-tooweak{
            color: #333333;
        }
        &.bg-weak{
            color: #EA0C0D;
        }
        &.bg-fair{
            color: #FFAD00;
        }
        &.bg-good{
            color: #9CC25A;
        }
        &.bg-strong{
            color: #02B502;
        }
    }

}


/* phone flag css */
.phone-input {
    font-size: 12px !important;
    border-color: #e6e6e6 !important;
  
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      border-color: #e6e6e6 !important;
    }
  }
  
  .countrycode-dropdown {
    width: auto !important;
  }

  .country{
    background-color: white !important;

    &:hover{
        background-color: #e6e6e6  !important;
    }
  }
  

  .countrycode-button{

   .selected-flag.open:before{
        outline: none !important;
      box-shadow: none !important;
     border: none !important;
    }
    .selected-flag:focus:before{
        outline: none !important;
      box-shadow: none !important;
     border: none !important;
    }
  }

