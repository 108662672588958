.vvv_notification_component{
  
    .noti-static-msg p{
      width: auto;  
    }
    
    
    .highlight{
    color: #FF6900;
    font-size: 11px; 
    font-weight: 500;
  }
  
  .notification_content_web{
      max-height: calc(100vh - 200px) !important;
      overflow-y: auto !important;
  }

    .notification-page{
      padding-right: 1em;
      min-height: 71dvh;
      max-height: 71dvh;
      overflow-y: auto;
    }
    
    .p-paginator {
      .p-paginator-first{
          
          margin-left: auto;
      }
      

      
        padding-inline: 0 ;
        
      
  
      .p-paginator-next,.p-paginator-last{
          color: #FF6900;
      }
  
      .p-paginator-pages .p-paginator-page,
      .p-paginator-element {
          min-width: 32px;
          height: 32px;
      }      
    }



    .notification-container{
      min-height: 61px;
      min-width: -webkit-fill-available;
      max-width: 1115px;
      overflow-x: hidden;
      background-color: #FCFCFC;
      border-bottom: 1px solid #E9E9E9;

      .notification-title{
        font-size: 14px;
        color: #222529;
        font-weight: 500;

      }

      .notification-message{
        color: #5A6474;
        /* font-size: 13px; */
        font-size: 11px !important;
        font-weight: 500;
        max-width: 425px;
        
      }
      
      .single-message{
        max-width: 900px;
      }

      .notification-time{
        color: #333333;
        font-weight: 400;
        //font-size: 12px;
        font-size: 11px;
      }

      .notification-timeremain{
          font-size: 10.5px;
          font-weight: 400;
          color: #5A6474;
      }

      i{
        font-size: 14px;
      }

      img{
        height: 14px;
        width: 14px;
      }


      .p-button{
        min-width: 126px;
        height: 30px;
        
      }

    }

    .new-notification{
      background-color: #ADDCFF17 !important;
    }

    .notification-type{
      color: #FF6900;
      font-size: 15px;
      font-weight: 500;
    }

}



/* Media query for mobile phones */
@media (max-width: 768px) {

  

.notification-page-header{
  .viv_pageHeader{
    padding-block: 0 !important;
  }
}

.vvv_notification_component{

  
  
.viv_paginator{
  padding-bottom: 5px !important;
  //margin-right: 10px;
  padding-right: 2em;
}

.noti-static-msg p {
  max-width: 250px;
}

/* .notification-page{
  min-height: 75dvh !important;
  max-height: 75dvh !important;
} */

}


}


