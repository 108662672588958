@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.slot-event-panel{
    
    .court-count-wraper {
        background: #C5E6FF;
        border: 1px solid #ADDCFF;
        min-width: 28.7px;
        min-height: 27.8px;
       border-radius: 100%;
       display: flex;
       flex-direction: row;
       justify-items: center;
       justify-content: center;
    
        p {
            font-size: 14.41px;
            font-weight: 500;
            color: #333333;
            line-height: 21.62px;
            font-family: 'Poppins';
            align-self: center;
        }
    }

    .p-panel-header{
        background: #fff;
    }

    .p-panel-content{
        background-color: #FCFCFC;
    }

    .p-panel-header-icon{
        background: white !important;
        &:focus{
            box-shadow: none !important;
            outline: none !important;
        }
        span{
            font-size: 7px;
            color: #000000;
        }
    }

    .p-button{
        width: 126px !important;
    }

}




.slot-event-list{
    max-height: 459px;
    overflow-y: auto;
}

.slot-event-title{
    font-size: 15px;
    color:#333333;
    font-family: "Roboto", sans-serif;
    line-height: 17.58px;
}


.slot-event-dialog-header{
    
 border-bottom: 1.58px solid #0000001A;
 
}

.slot-event-dialog-header-h1{
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 19.76px;
    line-height: 23.15px;
    letter-spacing: 0.41px;
}


.slot-event-detail-col{
    display: flex;
    flex-direction: row;
    
}

.slot-event-detail-col>i{
    font-size: 14px;
    margin-top: 1px;

}


.slot-event-detail-col>p{
    font-size: 14.63px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 29.26px;
    margin-top: -6px;
    color: #000000;
}

.slot-event-detail-col>p>span{
    font-size: 13.41px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 29.26px;
    text-align: center;
    color: #333333;
}

.slot-event-detail-edit>img{
width: 9px;
height: 10px;
cursor: pointer;
}




.booked_event_header{
    border-left: 4px solid #FF9F9F !important;
}
.booked_event_court-count-wraper{
    background-color: #FFCFCF !important;
    border-color: #FF9F9F !important;
}

.schedule_event_header{
    border-left: 4px solid #EBA1FF !important;
}
.schedule_event_court-count-wraper{
    background-color: #F9E3FF !important;
    border-color: #EBA1FF !important;
}

.private_event_header{
    border-left: 4px solid #ADDCFF !important;
}
.private_event_court-count-wraper{
    background-color: #E3F3FF !important;
    border-color: #ADDCFF !important;
}



.booked-info>h1{
    max-width: 416px;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.26px;
    color: #333333;
    line-height: 17.58px;
    font-family: "Roboto", sans-serif;
}


/* Mobile screen adjustments */
@media (max-width: 768px) {
    .slot-event-detail-edit {
        order: -1; 
        width: 100%; 
        justify-content: space-between; 
    }

    .slot-event-title{
        font-size: 12px;
        line-height: 12px;
    }

    .court-count-wraper {
        
        
    
        p {
            font-size: 10px !important ;
            line-height: 10px !important;
        }
    }



.viv_dialog>.p-dialog-content{
    padding-inline: 10px !important;
    overflow: hidden;
}

    .token_cost{
        font-size: 10px !important ;
            line-height: 10px !important;
    }


    .booked-info>h1{
        font-size: 10px;
    }
    

}

