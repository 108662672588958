.success_popup {
    border-radius: 10px;

    
    .message-title{
        font-size: 15px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-weight: 600;
    }
    
    .p-dialog-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        .p-dialog-header-icon{
            color: var(--secondary-black) !important;

            &:hover{
                background: #F1F6FC !important;
            }

            &:focus{
                box-shadow: none;
            }
        }

        .p-dialog-title{
            font-size: x-large;
            font-weight: 600;
            line-height: 24px;
            color: #7C7C7C;
        }
    }

    .p-dialog-footer {
        
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: #ffffff;
        padding: 10px;
    }

    .p-dialog-content{
        font-size: 15px;
        text-align: center;
        padding: 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-weight: 600;
    }

    .p-button-text{
        background-color: #F1F6FC;
        border: 1px solid #D6E4F7;
        color: var(--secondary-black) !important;

        &:hover{
            background-color: #F1F6FC !important;
        border: 1px solid #D6E4F7 !important;
        }
    }
}
